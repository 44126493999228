<template>

  <div class="screen" v-bind:style="colorStyle">

<!--    <img class="bg" src="@/assets/bg_yirui.jpg"/>-->
    <img class="bg" :src="background"/>

    <div class="content">

      <div class="row" style="justify-content: flex-end">

        <div class="container" style="width: 30%;margin-right:20px">
          <div class="title">公 告 栏</div>
          <div class="sub-title">BULLETIN BOARD</div>
          <div class="notice-content" v-html="screen.notice"></div>
        </div>

        <div style="height: 100%;display: grid;grid-template-rows: repeat(2,1fr);grid-gap: 20px;min-width: 24%">

          <div class="container clickable" @click="clickWeather">
            <div class="title">天 气 情 况</div>
            <div class="sub-title">WEATHER CONDITIONS</div>
            <div class="weather-text">{{ weather.cityname }} {{ weather.weather }} {{ weather.temp }}℃</div>
          </div>

          <div class="container">
            <div class="title">日 期</div>
            <div class="sub-title">DATE</div>
            <div class="weather-text">
              {{ date }}<br/>{{ time }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="container" style="width: 100%">
          <div class="title">泳 池 水 质 实 时 监 测</div>
          <div class="sub-title">REAL-TIME MONITORING OF WATER QUALITY</div>
          <div class="para-row">

            <div class="para-item" style="margin-top: 5%">
              <img src="@/assets/bg_blister.png"/>
              <div class="data-container">
                <div class="name">PH</div>
                <div class="value">{{ demoValueMap.reg5 || valueMap.reg5 }}</div>
              </div>
            </div>

            <div class="para-item">
              <img src="@/assets/bg_blister.png"/>
              <div class="data-container">
                <div class="name">余氯</div>
                <div class="value">{{ demoValueMap.reg6 || valueMap.reg6 }}</div>
              </div>
            </div>

            <div class="para-item" style="margin-top: 10%">
              <img src="@/assets/bg_blister.png"/>
              <div class="data-container">
                <div class="name">浊度</div>
                <div class="value">{{ demoValueMap.reg7 || valueMap.reg7 }}</div>
              </div>
            </div>

            <div class="para-item">
              <img src="@/assets/bg_blister.png"/>
              <div class="data-container">
                <div class="name">ORP</div>
                <div class="value">{{ demoValueMap.reg4 || valueMap.reg4 }}</div>
              </div>
            </div>

            <div class="para-item" style="margin-top: 8%">
              <img src="@/assets/bg_blister.png"/>
              <div class="data-container">
                <div class="name">水温</div>
                <div class="value">{{ demoValueMap.reg3 || valueMap.reg3 }}</div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <SelectCityModal ref="selectCityModal"></SelectCityModal>

  </div>
</template>

<script>

import SelectCityModal from "./_components/SelectCityModal";
import Lang from "./Lang"
import moment from 'moment'

moment.locale('zh-cn')

export default {
  components: {SelectCityModal},
  props: ["screen", "valueMap","deviceId"],
  data() {
    return {
      langZh: true,
      weather: {},
      date: "",
      time: "",
      interval: "",
      city:localStorage.getItem("SCREEN_WEATHER_CITY")||"",
      demoValueMap:{

      }
    }
  },
  computed: {
    langRes() {
      return this.langZh ? Lang.zh : Lang.en
    },
    background() {
      return this.screen.background || require('@/assets/bg_yirui.jpg')
    },
    colorStyle() {
      return {
        fontSize: this.screen.fontSize + "px",
        color: this.screen.textColor || "#0084FF"
      }
    },
  },
  created() {
    this.updateWeather()
    this.updateTime()
    setInterval(() => {
      this.updateTime()
    }, 30 * 1000)

    this.$get(`web/iot/water/screen/paras/${this.deviceId}`).then(suc => {
      let paraArray = suc.data
      paraArray.forEach(item=>{
        if(item.value){
          this.demoValueMap[item.identifier] = item.value
        }
      })
    })
  },
  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    updateTime() {
      this.date = moment().format("YYYY年MM月DD日")
      this.time = moment().format("HH:mm")
    },
    clickWeather() {
      this.$refs.selectCityModal.show(input=>{
        this.city = input
        localStorage.setItem("SCREEN_WEATHER_CITY", input)
        this.updateWeather()
      })
    },
    updateWeather(){
      this.$get("web/iot/water/screen/weather?city="+this.city).then(suc => {
        this.weather = suc.data.now
      })
    }
  }
}
</script>

<style lang="less" scoped>
.screen {
  font-size: 100px;
  font-family: fzys;
  height: 100%;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    object-fit: cover;
    opacity: 1;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 10px;

    .row {
      display: flex;
      height: 50%;
      padding: 10px;
    }

    .container {
      background: rgba(255, 255, 255, 0.4);
      height: 100%;
      border-radius: 20px;
      padding: 1%;

      .title {
        font-size: 24%;
        text-align: center;
        font-weight: 600;
        margin-bottom: -10px;
      }

      .sub-title {
        font-size: 10%;
        text-align: center;
      }

      .notice-content {
        text-align: center;
        padding: 15px 20px;
        font-size: 20%;
      }

      .weather-text {
        font-size: 20%;
        text-align: center;
        padding: 5px 20px;
      }

      .para-row {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: -6%;
        padding: 0 5%;

        .para-item {
          position: relative;
          width: 18%;

          img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
          }

          .data-container {
            text-align: center;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;

            .name {
              margin-top: 20%;
              font-size: 20%;
            }

            .value {
              font-size: 40%;
              margin-top: -5%;
            }
          }
        }

      }
    }
  }

}

@font-face {
  font-family: fzys;
  src: url('./FZYS_Medium.TTF')
}


</style>
