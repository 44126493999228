<template>
  <a-modal title="请输入城市" v-model="visible" @ok="handleOk">

    <a-input v-model="value"/>

  </a-modal>
</template>
<script>
export  default  {
  data(){
    return {
      visible:false,
      value:"",
      onInput:null
    }
  },
  methods:{
    show(onInput){
      this.value=""
      this.onInput=onInput
      this.visible=true
    },
    handleOk(){
      this.onInput(this.value)
      this.visible=false
    }
  }
}
</script>

<style lang="less" scoped>

</style>
